const Utils = require('./utils.js');

(function($, window, document, undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  // ESLint happiness
  /* global google */

  const gmap = {
    /**
     * Creates maps
     *
     * Sample HTML structure:
     * <div class="c-google-map js-google-map" style="height: 210px" data-zoom="17">
     *   <div class="js-google-map-marker" data-lat="37.8722493" data-lng="-122.25943280000001"></div>
     * </div>
     */
    init() {
      const maps = document.querySelectorAll('.js-google-map');
      if( !Utils.localized('maps_api_key') ) {
        return;
      }

      if( maps.length > 0 ) { // eslint-disable-line no-magic-numbers
        maps.forEach(el => {
          this.createMap(el);
        })
        // $.getScript(`https://maps.googleapis.com/maps/api/js?key=${Utils.localized('maps_api_key')}`, (script, status) => {
        //   if(status == 'success') {
        //     Array.prototype.forEach.call(maps, (map) => {
        //       this.createNewMap(map);
        //     });
        //   }
        // });
      }
    },

    createMap(mapElement) {
      const map = $(mapElement);
      const address = map.data('map-address');

      if (!address.trim().length || typeof map.data('map-address') === 'undefined') {
        map.hide();
        return;
      }

      const hasLatLng = typeof map.data('lat') !== 'undefined' && typeof map.data('lng') !== 'undefined';
      let lat = null;
      let lng = null;

      if (hasLatLng) {
        lat = map.data('lat');
        lng = map.data('lng');
      }

      const zoom = map.data('zoom') || 16;

      const url = `//www.google.com/maps/embed/v1/place?key=${Utils.localized('maps_api_key')}&q=${address}&zoom=${zoom}`;

      const iframe = $('<iframe></iframe>', {
        width: '100%',
        height: '100%',
        src: encodeURI(url),
        frameborder: 0,
        center: hasLatLng ? `${lat},${lng}` : ''
      });

      map.html(iframe);
    },

    /**
     * This function will geocode address to LatLng object
     * @param address, map, mapElement
     * @returns n/a
     */
    geocodeAddress(address, map, mapElement) {
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode( { 'address': address}, (results, status) => {
        if (status == 'OK') {
          this.addMarker(false, map, results[0].geometry.location); // eslint-disable-line no-magic-numbers
          this.centerMap(map, mapElement);
        } else {
          console.log('Geocode was not successful for the following reason: ' + status);
        }
      });
    },

    /**
     * This function will render a Google Map onto the selected DOM element
     * @param mapElement
     * @returns {google.maps.Map}
     */
    createNewMap(mapElement) {
      const markers = mapElement.querySelectorAll('.js-google-map-marker'),
        address = mapElement.getAttribute('data-map-address'),
        mapZoomControl = mapElement.getAttribute('data-zoom-control') || true,
        mapTypeControl = mapElement.getAttribute('data-type-control') || true,
        mapStreetControl = mapElement.getAttribute('data-street-control') || true;

      const uiTac = $('body.page-style-set__tac');

      const mapStyleTac = [
        {
          "featureType": "all",
          "elementType": "geometry",
          "stylers": [
            {
              "saturation": "-100"
            }
          ]
        },
        {
          "featureType": "all",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "weight": "0.01"
            }
          ]
        },
        {
          "featureType": "all",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "saturation": "1"
            }
          ]
        },
        {
          "featureType": "all",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "saturation": "0"
            },
            {
              "visibility": "off"
            }
          ]
        }
      ];

      if( !markers.length && !address ) {
        return;
      }

      const args = {
        zoom: 16,
        center: new google.maps.LatLng(0, 0), // eslint-disable-line no-magic-numbers
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: uiTac.length,
        styles: uiTac.length ? mapStyleTac : [],
        zoomControl: parseInt(mapZoomControl),
        mapTypeControl: parseInt(mapTypeControl),
        streetViewControl: parseInt(mapStreetControl)
      };

      const map = new google.maps.Map(mapElement, args);

      // add a markers reference
      map.markers = [];


      if( address ) {
        this.geocodeAddress(address, map, mapElement);
      }else {
        Array.prototype.forEach.call(markers, (el) => {
          this.addMarker(el, map);
        });

        this.centerMap(map,mapElement);
      }

      return map;
    },

    /**
     * This function will add a marker to the selected Google Map
     *
     * @param markerElement
     * @param map
     */
    addMarker(markerElement, map, fromAddress = false) {
      let latlng;
      let markerIcon = '';

      if( fromAddress ) {
        latlng = fromAddress;
      }else {
        const lat = markerElement.getAttribute('data-lat');
        const lng = markerElement.getAttribute('data-lng');

        markerIcon = markerElement.getAttribute('data-marker') || '';

        latlng = new google.maps.LatLng(lat, lng);
      }

      const marker = new google.maps.Marker({
        position: latlng,
        map: map,
        icon: markerIcon,
      });

      map.markers.push(marker);
    },

    /**
     * This function will center the map, showing all markers attached to this map
     *
     * @param map
     * @param mapElement
     */
    centerMap(map, mapElement) {
      const bounds = new google.maps.LatLngBounds();

      Array.prototype.forEach.call(map.markers, function(marker) {
        const latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
        bounds.extend(latlng);
      });

      if (map.markers.length === 1) { // eslint-disable-line no-magic-numbers
        // set center of map
        map.setCenter(bounds.getCenter());
        const mapZoom = mapElement.getAttribute('data-zoom') || map.zoom

        map.setZoom(parseInt(mapZoom, 10));
      } else {
        // fit to bounds
        map.fitBounds(bounds);
      }
    }
  };

  module.exports = gmap;

})(jQuery, window, document);
