/**
 * Baguette Lightbox
 */

'use strict';

const Baguette = {

  /* global baguetteBox */

  lightbox() {
    baguetteBox.run('.c-block__gallery,.c-gallery__single', {
      fullScreen: false,
      noScrollbars: false,
      animation: 'fadeIn', // slideIn or fadeIn
    });

    baguetteBox.run('.wp-gallery', {
      fullScreen: false,
      noScrollbars: false,
      animation: 'fadeIn', // slideIn or fadeIn
    });
  },

  /**
   * Baguette init
   */
  init() {
    this.lightbox();
  }
};

module.exports = Baguette;
