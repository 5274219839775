
(function($, window, document, undefined) {
  'use strict';

  const Utils = {

    localized(data) {
      if( typeof x5_scripts != typeof undefined ) {
        return x5_scripts[data];
      }
    },

    getMaxHeight(element, includeMargin = false) {
      const heights = $(element).map(function() {
        return $(this).outerHeight(includeMargin);
      })

      return Math.max(...heights);
    },

    equalHeightElements(array, container, reset = 0, includeMargin = false) { // eslint-disable-line no-magic-numbers
      if( !$(container).length ) {
        return;
      }

      $.each(array, (i, el) => {
        const element = $(container).find(el);
        element.css('min-height', '1px');

        const maxHeight = this.getMaxHeight(element, includeMargin);

        if( $(window).width() > reset ) { // eslint-disable-line no-magic-numbers
          element.css('min-height', maxHeight);
        }else {
          element.css('min-height', '1px');
        }
      });
    },

    getUrlParams( url ) {
      const keyPairs = [],
        params = url.split('&');

      for(let i = params.length - 1; i >= 0; i--) { // eslint-disable-line no-magic-numbers
        keyPairs.push(params[i].split('='));
      }

      return keyPairs;
    },

    galleryItemOnEdge(gallery) {
      const items = gallery.find('.c-block__gallery-item'),
        positions = [];

      items.removeClass('c-block__gallery-item--right-edge c-block__gallery-item--left-edge');

      $.each(items, (i, el) => {
        const item = $(el),
          posLeft = item.position().left;

        positions.push(posLeft);
      });

      const maxPos = Math.max( ...positions ),
        minPos = Math.min( ...positions );

      $.each(items, (i, el) => {
        const item = $(el),
          posLeft = item.position().left;

        if( posLeft == maxPos ) {
          item.addClass('c-block__gallery-item--right-edge');
        }else if( posLeft == minPos ) {
          item.addClass('c-block__gallery-item--left-edge');
        }
      });
    }
  }

  module.exports = Utils;

})(jQuery, window, document);
