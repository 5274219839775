/*
  Project: Press Plus
  Author: Xfive
 */

// require('addtocalendar/addtocalendar.js');

const gmap = require('./google-maps.js');

//const redirectSelect = require('./redirect-select.js');
const pageBlocksNew = require('./page-blocks-new.js');
const Baguette = require('./baguette-box.js');
const cForm = require('./cform.js');
const Misc = require('./misc.js');

const SubmitForms = require('./jobs-submit-form.js');

gmap.init();

//redirectSelect.init();
pageBlocksNew.init();
cForm.init();
Misc.init();
Baguette.init();
SubmitForms.init();
